import React, { useState, useEffect } from "react";
import { Link, withPrefix } from "gatsby";
import { Container, Navbar, Nav, NavDropdown, NavItem, Button } from "react-bootstrap";
import ModalCita from "./widgets/modalContactanos";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module';

import { Script } from "gatsby";

import Image from "./widgets/basico/image";

export default function Header ({selectedMenuItem, navActivo, pararmUTM, parameters, marca}) {
    //console.log("Estamos en header: ", parameters);
    const [cita, setCita] = useState(false);
    const [ colorHeader, setColorHeader ] = useState("barraMenuChevrolet");
    const [ imgHeader, setImageHeader ] = useState();
    const [ empresa, setEmpresa ] = useState("chevrolet");
    const [ contacto, setContacto ] = useState();

    const [ contactaIcon, setContactaIcon ] = useState({
        telefono: "",
        whatsapp: "",
    });

    const [ contactaIconNissan, setContactaIconNissan ] = useState({
        telefono: "",
        whatsapp: "",
    });
    
    const tallerOptions = ["taller-servicio", "taller-colision", "refacciones"];
    const weOptions = ["quienes-somos", "horario", "blog"];
    const promoOptions = ["promo-cars", "promo-accesorios", "promo-taller"];
    const [ windowSplit, setWindowSplit ] = useState();

    const NavDropItem = ({ children, selected, title, to }) => {
        return (
            <NavDropdown.Item
                as={Link}
                to={withPrefix(to)}
                className={selected ? "selected text-primary" : "text-header-inactivo"}
            >
                {title || null}
                {children || null}
            </NavDropdown.Item>
            );
    };
    //console.log("Estamos en el HEADER, los parametros: ", parameters);
    useEffect(() => {

        let splitUrl = window.location.pathname.split("/");
    
        setWindowSplit(splitUrl[1]);
        console.log("SplitUtl, ", splitUrl[1]);
        
        if(navActivo === "Principal"){
            setEmpresa(true);
            setColorHeader("barraMenuChevrolet");
            setImageHeader("chevrolet-rivero-nuevo.png")
            setContactaIcon({
                ...contactaIcon,
                whatsapp: "boton_chevrolet_header_whatsapp.png",
                telefono: "boton_chevrolet_header_telefono.png"
            })
        } else if (splitUrl[1] === "buick"){
            setEmpresa(false);
            setImageHeader("buick-home-nuevo.png");
            setColorHeader("barraMenuLujo");
            setContactaIcon({
                ...contactaIcon,
                whatsapp: "boton_chevrolet_header_whatsapp.png",
                telefono: "boton_chevrolet_header_telefono.png"
            })
        } else if (splitUrl[1] === "cadillac"){
            setEmpresa(false);
            setImageHeader("cadillac-home-nuevo.png");
            setColorHeader("barraMenuLujo");
            setContactaIcon({
                ...contactaIcon,
                whatsapp: "boton_chevrolet_header_whatsapp.png",
                telefono: "boton_chevrolet_header_telefono.png"
            })
        } else if (splitUrl[1] === "gmc"){
            setEmpresa(false);
            setImageHeader("gmc-home-nuevo.png");
            setColorHeader("barraMenuLujo");
            setContactaIcon({
                ...contactaIcon,
                whatsapp: "boton_chevrolet_header_whatsapp.png",
                telefono: "boton_chevrolet_header_telefono.png"
            })
        } else {  
             
            setEmpresa(true);
            setColorHeader("barraMenuChevrolet");
            setImageHeader("chevrolet-rivero-nuevo.png")
            setContactaIcon({
                ...contactaIcon,
                whatsapp: "boton_chevrolet_header_whatsapp.png",
                telefono: "boton_chevrolet_header_telefono.png"
            })
        }

    }, [])

    let tagManagerArgs = {
        gtmId: 'GTM-W7HQJGC6', //Este ID se obtiene en el admin de GTM
        dataLayer: {
            'event': contacto,
        }
    }

    useEffect (() => {

        if (tagManagerArgs.dataLayer.event != undefined){
            //console.log(tagManagerArgs, "Analytics");
            TagManager.initialize(tagManagerArgs);
        }
    }, [tagManagerArgs]);

    function sendData(num){ 
        setContacto(num);
    }

    //Detector parametros url que tendrá durante la página

    return(
        <React.Fragment>
           {/*  <Script id="gtag-config" strategy="off-main-thread" forward={[`gtag`]}>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments)};
                    gtag('js', new Date());
                    gtag('config', 'G-12BFK4L633')
                `}
            </Script> */}

            { <Helmet
                script={[
                    {
                        innerHTML: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-NND5SRVW');
                        `,
                    },
                ]}
            ></Helmet>}
            
{            <Helmet
                script={[
                    {
                        innerHTML: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                        
                            gtag('config', 'G-12BFK4L633');
                        `,
                    },
                ]}
            ></Helmet>}
            {<Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-12BFK4L633"></script>
            </Helmet>}

            {<Helmet>
                <script src="https://exo.autogenius.io/" ></script>
            </Helmet>}
  
            {/*<!-- Google Tag Manager (noscript) -->*/}

            <Helmet
                script={[
                    {
                        innerHTML: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-5TRQR57J');

                        `,
                    },
                ]}
            ></Helmet>

            {/*<!-- End Google Tag Manager (noscript) -->*/}
 

            {/* <!-- Facebook Pixel Code --> */}
            <Helmet
                script={[
                    {
                        innerHTML: `
                        !function(f,b,e,v,n,t,s)
                        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                        n.queue=[];t=b.createElement(e);t.async=!0;
                        t.src=v;s=b.getElementsByTagName(e)[0];
                        s.parentNode.insertBefore(t,s)}(window,document,'script',
                        'https://connect.facebook.net/en_US/fbevents.js');
                        fbq('init', '3699569276956940');
                        fbq('track', 'PageView');
                        `,
                    },
                ]}
            ></Helmet>
            <Helmet>
                <noscript>
                    {`<img height="1" width="1" src="https://www.facebook.com/tr?id=3699569276956940&ev=PageView&noscript=1"/>`}
                </noscript>
            </Helmet>
            {/* <!-- End Facebook Pixel Code --> */}

            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NND5SRVW" height="0" width="0" style={{display: "none", visibility: "hidden"}}></iframe>
            </noscript>

            <Navbar expand="lg" fixed="top" variant="dark" id={navActivo === "Principal" ? "barritaDebajo" : `${colorHeader}`}>
                <Container style={{height: '100%'}}>
                    <Navbar.Brand>
                        {
                            empresa === true ? 
                                <>
                                    <Link to={parameters ? "/" + parameters : "/"} id="headerImageBrand">
                                        <Image
                                            className="d-md-block d-none"
                                            fileName={imgHeader}
                                            alt="banner"
                                            imgStyles={{position: 'absolute', objectFit: 'contain', width: '100%'}}
                                        />
                                    </Link>
                                </>
                            : 
                            <>
                                    <Link to={parameters ? "https://www.gruporivero.com/" + parameters : "https://www.gruporivero.com"} id="headerImageBrand">
                                        <Image
                                            className="d-md-block d-none"
                                            fileName={imgHeader}
                                            alt="banner"
                                            imgStyles={{position: 'absolute', objectFit: 'contain', width: '100%'}}
                                        />
                                    </Link>
                            </>
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" variant="white"/> 
                    <Navbar.Collapse className="text-right">
                        <Navbar>
                            <Nav className="small-menu-text">

                                { empresa === true ? 
                                    <>
                                        <NavDropItem
                                            to={parameters ?  "/chevrolet/catalogo/nuevos" + parameters  :   "/chevrolet/catalogo/nuevos"}
                                            title="Autos Nuevos"
                                            id="Catalogo Nuevos Header"
                                        />  

                                        <NavItem>
                                            <hr />
                                        </NavItem>

                                        <Nav >
                                            <NavDropItem
                                                className="d-md-block text-white"
                                                to={parameters ? "catalogo/seminuevos/" + parameters : "catalogo/seminuevos/"}
                                                id="Seminuevos Header"
                                                selected={selectedMenuItem === "seminuevos"}
                                                title="Seminuevos"
                                            />
                                        </Nav>

                                        <NavDropdown title="Servicios de Taller" variant="dark" 
                                        className={ tallerOptions.indexOf(selectedMenuItem) > -1 ? "text-primary selected" : "NavDropDownPadding"}>
                                            <NavDropItem
                                                to={parameters ? "/taller-de-servicio/" + parameters : "/taller-de-servicio/"}
                                                title="Taller de Servicio"
                                                id="Taller de Servicio Header"
                                            />
                                            <NavDropItem
                                                to={parameters ? "/taller-de-colision/" + parameters : "/taller-de-colision/"}
                                                title="Taller de Colisión"
                                                id="Taller de Colision Header"
                                            />
                                            {empresa !== 'nissan' && (
                                                <NavDropItem
                                                    to={parameters ? "/refacciones/" + parameters : "/refacciones/"}
                                                    title="Refacciones"
                                                    id="Refacciones Header"
                                                />
                                            )}
                                            {/* <NavDropItem
                                                to={
                                                    empresa == 'nissan'?
                                                    parameters
                                                    ? "refacciones-nissan" + parameters
                                                    : "refacciones-nissan"
                                                    :
                                                    parameters
                                                    ? "refacciones" + parameters
                                                    : "refacciones"
                                                }
                                                title="Refacciones"
                                                id="Refacciones Header"
                                            /> */}
                                        </NavDropdown>

                                        <NavDropdown title="Nosotros" variant="dark" className={ weOptions.indexOf(selectedMenuItem) > -1 ? "text-primary selected" : "NavDropDownPadding"} >
                                            <NavDropItem
                                                style={{width: '100% !important'}}
                                                to={parameters ? "quienes-somos" + parameters : "quienes-somos"}
                                                title="¿Quiénes somos?"
                                                id="Quienes somos Header"
                                            />
                                            <NavDropItem
                                                to={ parameters ? "contacto" + parameters : "contacto"}
                                                title="Horarios y Direcciones"
                                                id="Contacto Header"
                                            />
                                            {empresa === "principal" ? (
                                                    null
                                            ) : (
                                                <NavDropItem
                                                    to={parameters ? "/rivero/blogs" + parameters : "/rivero/blogs"}
                                                    title="Blog"
                                                    id="Blog Header"
                                                />
                                            )}
                                        </NavDropdown>

                                        <NavDropdown title="Promociones" variant="dark" className={promoOptions.indexOf(selectedMenuItem) > -1 ? "text-primary selected" : "NavDropDownPadding"}
                                            style={{ display: empresa === 'nissan' ? 'none' : empresa === 'principal' ? 'none' : 'block' }}
                                            >
                                            <NavDropItem
                                                to={parameters ? "promociones/nuevos" + parameters : "promociones/nuevos"}
                                                title="Carros Nuevos"
                                                id="Promociones Nuevos Header"
                                            />
                                            <NavDropItem
                                                to={parameters ? "promociones/accesorios" + parameters : "promociones/accesorios"}
                                                title="Accesorios"
                                                id="Promociones Accesorios Header"
                                            />
                                            <NavDropItem
                                                to={parameters ? "promociones/taller-de-servicio" + parameters : "promociones/taller-de-servicio"}
                                                title="Taller"
                                                id="Promociones Taller Servicio Header"
                                            />
                                        </NavDropdown>
                                    </>
                                : null
                                }

                                <div
                                    style={{
                                    height: "150px",
                                    width: "100%",
                                    top: 0,
                                    background: "rgba(255,255,255,0.3)",
                                    }}
                                    className="d-block d-sm-none"
                                >

                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} className="d-md-block d-lg-none">
                                    <div style={{display: 'flex'}}>
                                        <a onClick={() => sendData('dd_whatsapp_8116001600')} href="https://wa.me/+5218116001600" target="_blank" rel="noreferrer" id="headerIconBoton" className="px-1">
                                            {/*<Image
                                                fileName={contactaIcon.whatsapp}
                                                alt="banner"
                                                imgStyles={{position: 'absolute', objectFit: 'cover', width: '100%', borderRadius: 5}}
                                                style={{width: '100px', height: 20}}
                                            />*/}
                                            WhatsApp: 81-1600-1600
                                        </a>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <a onClick={() => sendData('dd_tel_1257')} href="tel:8112571257" target="_blank" rel="noreferrer" id="headerIconBoton">
                                                {/*<Image
                                                    fileName={contactaIcon.telefono}
                                                    alt="banner"
                                                    imgStyles={{position: 'absolute', objectFit: 'contain', width: '100%'}}
                                                    style={{width: '100%'}}
                                                />*/}
                                            Teléfono: 81-1257-1257
                                        </a>
                                    </div>
                                </div> 

                                </div>
                            </Nav>
                            <div className="d-lg-block d-none">
                                <Nav>
                                    <div id="headerBarraDivision"></div>
                                    <div id="headerBotonesContactar" style={{display: 'flex', flexDirection: 'column'}}>
                                        <>
                                            <a onClick={() => sendData('dd_tel_1257')} href="tel:8112571257" target="_blank" rel="noreferrer" id="headerIconBoton">
                                                <Image
                                                    className="d-md-block d-none"
                                                    fileName={contactaIcon.telefono}
                                                    alt="banner"
                                                    imgStyles={{position: 'absolute', objectFit: 'contain'}}
                                                />
                                            </a>
                                            <a onClick={() => sendData('dd_whatsapp_8116001600')} href="https://wa.me/+5218116001600" target="_blank" rel="noreferrer" id="headerIconBoton">
                                                <Image
                                                    className="d-md-block d-none"
                                                    fileName={contactaIcon.whatsapp}
                                                    alt="banner"
                                                    imgStyles={{position: 'absolute'}}
                                                />
                                            </a>
                                        </>
                                    </div>
                                </Nav>
                            </div>
                        </Navbar>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <ModalCita show={cita} onHide={() => setCita(false)}/>
        </React.Fragment>
    )

} 
